<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const logos = [
  {
    src: '/img/czech_crunch.svg',
    alt: 'Czech Crunch',
    width: 140,
    height: 17,
  },
  {
    src: '/img/finlord.svg',
    alt: 'Finloard',
    width: 119,
    height: 41,
  },
  {
    src: '/img/finmag.svg',
    alt: 'Finmag',
    width: 110,
    height: 38,
  },
  {
    src: '/img/seznam-zpravy.svg',
    alt: 'Seznam Zprávy',
    width: 153,
    height: 23,
  },
  {
    src: '/img/penize.svg',
    alt: 'Peníze.cz',
    width: 128,
    height: 45,
  },
]
</script>

<template>
  <div class="flex items-center">
    <CfgTypography
      class="mx-auto mb-10 text-center lg:text-left"
      :size="CfgTypographySizes.h2"
      tag-name="h2"
    >
      {{ $t('homepage.wroteAboutUs.title') }}
    </CfgTypography>
  </div>

  <div class="grid grid-cols-1 justify-between px-0 text-center md:flex md:px-10">
    <div
      v-for="(item, index) in logos"
      :key="index"
      class="flex items-center justify-center py-5"
    >
      <LazyNuxtImg
        :alt="item.alt"
        class="min-w-[50%] px-5 md:max-h-[45px] md:min-h-[20px] lg:px-0"
        fit="contain"
        :height="item.height"
        loading="lazy"
        placeholder
        :src="item.src"
        :width="item.width"
      />
    </div>
  </div>
</template>
